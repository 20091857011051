import { logout } from "../models/auth";

export default [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("../components/auth/LoginView.vue"),
		meta: {
			noAuth: true,
		},
	},
	{
		path: "/logout",
		name: "Logout",
		beforeEnter() {
			logout();
		},
		component: () => {},
		meta: {
			noAuth: true,
		},
	},
	{
		path: "/mechanic-signup",
		name: "MechanicSignup",
		component: () => import("../components/auth/SignupView.vue"),
		meta: {
			noAuth: true,
		},
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () => import("../components/auth/ResetPassword.vue"),
		props: true,
		meta: {
			noAuth: true,
		},
	},
];
