import axios from "axios";
import { logout } from "../models/auth";

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "https://api.fixie.nz";

// Set up request interceptor to add Authorization header
axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	error => Promise.reject(error),
);

// Set up response interceptor for token refreshing
axios.interceptors.response.use(
	response => response,
	async error => {
		//invalid permissions or token logout
		const status = error?.response?.status;
		if (status == 403 || status == 401) logout({ noApiCall: true });

		//return error
		const data = error?.response?.data ?? {};
		const fullError = {
			error,
			...(typeof data == "string" ? { message: data } : data),
		};
		return Promise.reject(fullError);
	},
);
