import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./style.css";

//create app
const app = createApp(App);

//setup store
import { createPinia } from "pinia";
const pinia = createPinia();
app.use(pinia);

//use routes
app.use(router);

//config
require("./configuration/axios");
require("./models/auth");

//call storage (to delete any expired items on load)
require("./models/storage");

//mount
app.mount("#app");
