<template>
	<div
		:class="{
			'globalAlert chamfer': true,
			show: isShown,
			[type]: true,
		}"
	>
		{{ message }}
	</div>
</template>

<style scoped>
.globalAlert {
	position: fixed;
	right: 30px;
	bottom: -200px;
	width: 350px;
	background: var(--successLight);
	padding: 10px;
	transition: 200ms;
}
.globalAlert.show {
	bottom: 30px;
}
@media only screen and (max-width: 580px) {
	.globalAlert {
		left: 30px;
	}
}

.globalAlert.error {
	background-color: var(--dangerLight);
}
</style>

<script>
import { useAppStore } from "../../stores/app";

export default {
	name: "GlobalAlert",
	data() {
		return {
			isShown: false,
			type: "error",
			message: "",
		};
	},
	methods: {
		show(message, type = "error", duration = 3500) {
			this.message = message;
			this.type = type;
			this.isShown = true;
			if (duration) setTimeout(this.close, duration);
		},
		close() {
			this.isShown = false;
			setTimeout(() => {
				this.message = "";
				this.type = "";
			}, 250);
		},
	},
	mounted() {
		const appStore = useAppStore();
		appStore.globalAlert.show = this.show;
		appStore.globalAlert.close = this.close;
	},
};
</script>
