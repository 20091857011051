<template>
	<AppHeader />
	<main id="main">
		<router-view />
	</main>
	<AppFooter />
	<GlobalAlert />
</template>

<script>
import AppHeader from "./components/frame/AppHeader.vue";
import AppFooter from "./components/frame/AppFooter.vue";
import GlobalAlert from "./components/utils/GlobalAlert.vue";
import UpdateMixin from "./components/utils/UpdateMixin";
import { useAppStore } from "./stores/app";

export default {
	components: { AppHeader, AppFooter, GlobalAlert },
	mixins: [UpdateMixin],
	beforeMount() {
		const appStore = useAppStore();
		window.addEventListener("beforeinstallprompt", e => {
			e.preventDefault();
			appStore.setData("installEvent", e);
		});
	},
};
</script>
