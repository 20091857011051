export default [
	{
		path: "/pay/:invoiceId",
		name: "PayInvoice",
		component: () => import("../components/invoicing/PayView.vue"),
		props: true,
		meta: {
			noAuth: true,
		},
	},
];
