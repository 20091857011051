export default [
	{
		path: "/users",
		name: "UserList",
		component: () => import("../components/users/UserList.vue"),
		children: [
			{
				path: ":userId",
				name: "UserId",
				props: true,
				redirect: { name: "UserView" },
				children: [
					{
						path: "view",
						name: "UserView",
						props: true,
						component: () =>
							import("../components/users/UserView.vue"),
					},
				],
			},
		],
	},
];
