import axios from "axios";
import { useUserStore } from "../stores/user";
import router from "../router";
import StorageLocal from "./storage";
import { useAppStore } from "../stores/app";

function decodeToken(token) {
	if (!token) return null;
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	return JSON.parse(jsonPayload);
}

export async function refreshToken() {
	try {
		const response = await axios.get("/auth/refresh-token");
		const newToken = response.data.token;
		setToken(newToken);
	} catch (err) {
		console.error("Failed to refresh token:", err);
		logout();
	}
}

export function setToken(newToken) {
	localStorage.setItem("token", newToken);
	setRefreshTimer();
}

let refreshTimer = null;
function setRefreshTimer() {
	const data = getDecodedToken();
	if (!data) return;
	const exp = data.exp;
	const refreshIn = exp * 1000 - Date.now() - 120000; //2 min before expiry
	// const refreshIn = 5000 + exp - exp; //for testing 5 secs
	refreshTimer = setTimeout(refreshToken, refreshIn);
}

export function deleteToken() {
	localStorage.removeItem("token");
}

export function getToken() {
	const token = localStorage.getItem("token");
	return token;
}

export function getDecodedToken() {
	const token = getToken();
	return decodeToken(token);
}

export function isLoggedIn() {
	const decoded = getDecodedToken();
	if (!decoded) return false;
	const valid = Date.now() / 1000 - decoded.exp;
	if (!valid) return false;
	return true;
}

export async function login(email, password) {
	//handle login
	const response = await axios.post("/auth/login", { email, password });
	const token = response.data.token;

	//set token
	setToken(token);

	//put user in store
	const userStore = useUserStore();
	userStore.login();

	promptInstall();

	//decode and return
	return decodeToken(token);
}

export async function promptInstall() {
	//check not denied
	const denied = StorageLocal.getData("install_prompt_response");
	if (denied?.outcome == "dismissed") return;

	//check is active user
	const userStore = useUserStore();
	await userStore.userPromise;
	const isActive = ["active", "pending"].includes(userStore.user?.status);
	if (!isActive) return;

	//prompt install event if captured
	const appStore = useAppStore();
	const installEvent = appStore.getData("installEvent");
	if (installEvent) {
		installEvent.prompt();
		installEvent.userChoice.then(choice => {
			StorageLocal.storeData(
				"install_prompt_response",
				choice,
				//30 days
				30 * 24 * 60 * 60 * 1000,
			);
		});
	}
}

export function getLoginRedirect() {
	const tokenData = getDecodedToken();

	switch (tokenData.team_name) {
		case "mechanic": {
			if (tokenData.user_status == "pending")
				return { name: "OnboardingOverview" };
			else return { name: "BookingList" };
		}
		default:
			return { name: "BookingList" };
	}
}

export async function logout(options = {}) {
	if (!options.noApiCall) {
		try {
			//remove refresh timer
			clearTimeout(refreshTimer);

			//handle logout
			await axios.post("/auth/logout");
		} catch (err) {
			console.error(err);
		}
	}

	//clear token
	deleteToken();
	useUserStore().logout();

	//push to login view if curent route requires auth
	if (!router.currentRoute.value?.meta?.noAuth)
		router.replace({ name: "Login" });
}

//on app load:
setRefreshTimer();
