import { defineStore } from "pinia";
import {
	getDecodedToken,
	isLoggedIn as getLoggedIn,
	logout,
} from "../models/auth";
import axios from "axios";

export const useUserStore = defineStore("user", {
	state: () => {
		const isLoggedIn = getLoggedIn();
		let resolveUser;
		const userPromise = new Promise(res => {
			resolveUser = res;
		});

		//init that requires the store to be inited
		setTimeout(() => {
			const store = useUserStore();

			if (store.isLoggedIn) store.loadUser().then(store.resolveUser);
			else store.resolveUser(false);
		});

		return {
			isLoggedIn,
			user: {},
			userPromise,
			resolveUser,
		};
	},
	getters: {
		userId() {
			return this.user?.user_id;
		},
	},
	actions: {
		login() {
			this.isLoggedIn = true;
			this.loadUser().then(this.resolveUser);
		},
		logout() {
			this.isLoggedIn = false;
			this.user = {};

			//reset promise
			this.userPromise = new Promise(res => {
				this.resolveUser = res;
			});
		},
		async loadUser() {
			try {
				const response = await axios.get("/users/active-user");
				Object.assign(this.user, response.data.user);

				//if mechanic get email templates
				if (this.checkPermissions("mechanic")) {
					const emailTemplateResponse = await axios.get(
						`users/${this.user.user_id}/email-templates`,
					);
					this.user.emailTemplates =
						emailTemplateResponse.data.templates;
				}

				return this.user;
			} catch (err) {
				console.error(err);
				logout();
			}
		},
		checkPermissions(...requiredPermissions) {
			const token = getDecodedToken();
			if (!token) return false;
			const team = token.team_name;
			const status = token.user_status;
			if (!team || !status) return false;
			for (let requiredPermission of requiredPermissions) {
				//basic
				if (typeof requiredPermission == "string")
					if (team == requiredPermission) return true;

				//advanced
				if (requiredPermission.team != team) continue;

				if (requiredPermission.if) {
					const ok = requiredPermission.if(this.user);
					if (!ok) continue;
				}

				if (
					requiredPermission.status &&
					requiredPermission.status != status
				)
					continue;

				return true;
			}

			return false;
		},
	},
});
