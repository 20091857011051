export default [
	{
		path: "/profile",
		name: "Profile",
		component: () => import("../components/account/ProfileView.vue"),
	},
	{
		path: "/account",
		name: "Manage",
		component: () => import("../components/account/ManageView.vue"),
	},
	{
		path: "/onboarding",
		name: "OnboardingOverview",
		component: () =>
			import("../components/onboarding/OnboardingOverview.vue"),
	},
	{
		path: "/questionaire",
		name: "OnboardingQuestionaire",
		component: () =>
			import("../components/onboarding/OnboardingQuestionaire.vue"),
	},
];
