import { getDecodedToken } from "../models/auth";
import { useUserStore } from "../stores/user";

const logout = {
	label: "Logout",
	to: { name: "Logout" },
};

export const admin = [
	{
		label: "Bookings",
		to: { name: "BookingList" },
	},
	{
		label: "Users",
		to: { name: "UserList" },
	},
	logout,
];

export const mechanic = [
	{
		label: "Bookings",
		to: { name: "BookingList" },
	},
	// {
	// 	label: "Invoices",
	// 	to: { name: "AppHome" }, //TODO
	// },
	{
		label: "My account",
		children: [
			{
				label: "Profile",
				to: { name: "Profile" },
			},
			{
				label: "Manage",
				to: { name: "Manage" },
			},
		],
	},
	logout,
];

export const mechanicOnboarding = [
	{
		label: "Onboarding",
		to: { name: "OnboardingOverview" },
	},
	{
		label: "Profile",
		to: { name: "Profile" },
	},
	{
		label: "Account",
		to: { name: "Manage" },
	},
	logout,
];

export const rider = [
	{
		label: "Bookings",
		to: { name: "BookingList" },
	},
	logout,
];

const menuItems = {
	rider,
	admin,
};

export default function getMenuItems() {
	if (!useUserStore().isLoggedIn)
		return [
			{
				label: "Book a fix",
				to: { name: "MakeBooking" },
			},
			{
				label: "Login",
				to: { name: "Login" },
			},
		];

	const decodedToken = getDecodedToken();
	const teamName = decodedToken.team_name;
	const status = decodedToken.user_status;

	switch (teamName) {
		case "mechanic":
			switch (status) {
				case "active":
					return mechanic;
				case "pending":
					return mechanicOnboarding;
			}
			break;
		default:
			return menuItems[teamName] || [];
	}

	return [];
}
