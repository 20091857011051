<template>
  <footer>
    <div class="smallprint m-0">
      &#0169; Fixie
      {{ year }}. All rights reserved.<br />
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  setup() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
