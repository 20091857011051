function prepKey(key) {
	const curKeysRaw = localStorage.getItem("key_index");
	const curKeys = curKeysRaw ? JSON.parse(curKeysRaw) : [];
	const newKey = "fixie_" + key;
	const newKeys = [...new Set([...curKeys, key])];
	localStorage.setItem("key_index", JSON.stringify(newKeys));
	return newKey;
}

function clearExpiredItems() {
	//get key index
	const keysRaw = localStorage.getItem("key_index");
	const keys = JSON.parse(keysRaw);
	if (!keys) return localStorage.clear();

	//get each item to autodelete if expired
	for (const key of keys) getData(key);
}
clearExpiredItems();
setInterval(clearExpiredItems, 1000 * 60 * 60);

export function storeData(key, data, expiry) {
	//expiry in ms from now
	const now = Date.now();
	const toStore = {
		data,
		iat: now,
		exp: expiry ? now + expiry : false,
	};
	const fullKey = prepKey(key);
	localStorage.setItem(fullKey, JSON.stringify(toStore));
}

export function getData(key) {
	try {
		const fullKey = prepKey(key);
		const fromStore = localStorage.getItem(fullKey);
		if (!fromStore) throw "No data";
		const data = JSON.parse(fromStore);
		if (data.exp) {
			const now = Date.now();
			if (now > data.exp) {
				clearData(key);
				throw "Data has expired";
			}
		}
		return data.data;
	} catch (err) {
		// console.error("Error getting " + key, err);
		return undefined;
	}
}

export function clearData(key) {
	//remove item
	const fullKey = prepKey(key);
	localStorage.removeItem(fullKey);

	//remove key
	const curKeysRaw = localStorage.getItem("key_index");
	const curKeys = curKeysRaw ? JSON.parse(curKeysRaw) : [];
	const newKeys = curKeys.filter(k => k != key);
	localStorage.setItem("key_index", JSON.stringify(newKeys));
}

const StorageLocal = {
	storeData,
	getData,
	clearData,
};
export default StorageLocal;
