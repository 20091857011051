<template>
	<div
		:class="{
			navItem: true,
			navDropDownContainer: hasChildren,
		}"
	>
		<template v-if="hasChildren">
			<button @click.stop="toggleDropdown" class="navDropDownButton">
				{{ label }} &#9660;<span
					class="navDropDownButton"
					role="img"
					aria-label="Down arrow"
					style="padding: 0"
					alt=""
				></span>
			</button>

			<div
				:class="{
					navDropDownContent: true,
					show: isOpen,
				}"
			>
				<router-link
					v-for="child in children"
					:to="child.to"
					:key="JSON.stringify(child.to)"
				>
					{{ child.label }}
				</router-link>
			</div></template
		>
		<router-link v-else :to="to" class="navItem">{{ label }}</router-link>
	</div>
</template>
<script>
export default {
	name: "MenuItem",
	props: {
		label: String,
		to: [String, Object],
		children: Array,
		openDD: String,
	},
	emits: ["open"],
	computed: {
		hasChildren() {
			return this.children.length;
		},
		key() {
			return JSON.stringify(this.to) + this.label;
		},
		isOpen() {
			return this.openDD == this.key;
		},
	},
	methods: {
		toggleDropdown() {
			if (!this.isOpen) this.$emit("open", this.key);
			else this.$emit("open", "");
		},
	},
};
</script>
