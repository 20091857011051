export default [
	{
		path: "/bookings",
		name: "BookingList",
		component: () => import("../components/bookings/BookingList.vue"),
		children: [
			{
				path: ":bookingId",
				name: "BookingId",
				props: true,
				redirect: { name: "BookingOverview" },
				children: [
					{
						path: "view",
						name: "BookingOverview",
						props: true,
						component: () =>
							import(
								"../components/bookings/BookingOverview.vue"
							),
					},
					{
						path: "invoice",
						name: "BookingInvoice",
						props: true,
						component: () =>
							import(
								"../components/invoicing/BookingInvoice.vue"
							),
						children: [
							{
								path: ":invoiceId",
								name: "BookingInvoiceView",
								props: true,
								component: () =>
									import(
										"../components/invoicing/InvoiceView.vue"
									),
							},
						],
					},
					{
						path: ":jobId",
						name: "BookingJob",
						props: true,
						component: () =>
							import("../components/bookings/BookingJob.vue"),
					},
				],
			},
		],
	},
	{
		path: "/make-booking",
		name: "MakeBooking",
		component: () => import("../components/frame/CreateBooking.vue"),
		meta: {
			noAuth: true,
		},
		children: [
			{
				path: "user",
				name: "MakeBookingUserDetails",
				component: () =>
					import("../components/createBooking/UserDetails.vue"),
			},
			{
				path: "bikes",
				name: "MakeBookingBikes",
				component: () =>
					import("../components/createBooking/BikesJobs.vue"),
			},
			{
				path: "mechanics",
				name: "MakeBookingMechanics",
				component: () =>
					import("../components/createBooking/MechanicSelect.vue"),
			},
			{
				path: "preferences",
				name: "MakeBookingPreferences",
				component: () =>
					import(
						"../components/createBooking/BookingPreferences.vue"
					),
			},
			{
				path: "review",
				name: "MakeBookingReview",
				component: () =>
					import("../components/createBooking/ReviewConfirm.vue"),
			},
		],
	},
	{
		path: "/new-booking",
		name: "ConfirmNewBooking",
		component: () =>
			import("../components/createBooking/ConfirmNewBooking.vue"),
		meta: {
			noAuth: true,
		},
	},
];
