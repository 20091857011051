<template>
	<header>
		<h1 class="sr-only">Fixie</h1>
		<a href="#main" class="skip" style="top: 0; left: 0">
			Skip to main content
		</a>
		<nav ref="nav">
			<a :href="siteUrl">
				<img
					:src="siteUrl + '/assets/logo.svg'"
					class="navLogo"
					alt="Fixie"
				/>
			</a>

			<button
				v-if="menuItems.length"
				type="button"
				@click="toggleNav"
				:class="{
					menuToggle: true,
					show: menuOpen,
				}"
				id="menuToggle"
				aria-label="Open menu"
			>
				<div class="line1"></div>
				<div class="line2"></div>
				<div class="line3"></div>
			</button>

			<div :class="{ mainNav: true, show: menuOpen }">
				<MenuItem
					v-for="item in menuItems"
					:label="item.label"
					:to="item.to"
					:children="item.children || []"
					:key="JSON.stringify(item.to) + item.label"
					:openDD="openDD"
					@open="openDropdown"
					@click="closeNav"
				/>
			</div>
		</nav>
	</header>
</template>

<script>
import getMenuItems from "../../config/menuItems";
import MenuItem from "./MenuItem.vue";

export default {
	name: "AppHeader",
	components: { MenuItem },
	data() {
		return {
			menuOpen: false,
			siteUrl: process.env.VUE_APP_SITE_URL,
			openDD: "",
		};
	},
	computed: {
		menuItems() {
			return getMenuItems();
		},
	},
	methods: {
		toggleNav() {
			this.menuOpen = !this.menuOpen;
		},
		closeNav() {
			this.menuOpen = false;
		},
		openDropdown(item) {
			this.openDD = item;
		},
	},
	mounted() {
		const touchEvent = "ontouchstart" in window ? "touchstart" : "click";
		window.addEventListener(touchEvent, event => {
			const nav = this.$refs.nav;
			function isChildOfNav(element) {
				if (element == nav) return true;
				const parent = element.parentNode;
				if (!parent) return false;
				return isChildOfNav(parent);
			}
			const is = isChildOfNav(event.target);
			if (is) return;

			//hide dropdowns
			this.openDD = "";

			//hide mobile menu
			this.menuOpen = false;
		});
	},
};
</script>
