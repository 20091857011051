import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
	state: () => {
		return {
			globalAlert: { show: () => {}, close: () => {} },
			data: {},
		};
	},
	getters: {},
	actions: {
		setData(key, data) {
			this.data[key] = data;
		},
		appendData(key, data) {
			this.data[key] = {
				...this.data[key],
				...data,
			};
		},
		getData(key) {
			return this.data[key];
		},
		removeData(key) {
			delete this.data[key];
		},
	},
});
